import React from "react"
import SignedOutSidebar from "./signed-out-sidebar"
import SignedOutHeader from "./signed-out-header"
import Nearby from "./nearby"

class HomeComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
    }
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open })
  }

  render() {
    return (
      <div className="d-flex">
        <div className="desktop-only">
          <SignedOutSidebar> </SignedOutSidebar>
        </div>
        <div className="" style={{ width: "100%" }}>
          <SignedOutHeader></SignedOutHeader>
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12">
                <h2>Nearby</h2>
              </div>
              <Nearby></Nearby>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HomeComponent
